import React from 'react';

import { Box, Container, Flex, Text } from '@chakra-ui/react';

const NotFound: React.FC = () => {
  return (
    <Container mt={6}>
      <Box>
        <Flex justifyContent="center">
          <Text fontSize="2xl">Ooops! Página não encontrada</Text>
        </Flex>
        <Flex justifyContent="center" mt={-6}>
          <Text fontSize="250px">4</Text>
          <Text fontSize="250px">0</Text>
          <Text fontSize="250px">4</Text>
        </Flex>
        <Flex justifyContent="center">
          <Text fontSize="lg" mt={-6}>
            Desculpe, mas a página que você procura não foi encontrada.
          </Text>
        </Flex>
      </Box>
    </Container>
  );
};

export default NotFound;
