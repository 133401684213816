import React from 'react';
import ReactLoading from 'react-loading';

import { Container } from './styles';

const Loading: React.FC = () => {
  return (
    <Container className="isloading">
      <div>
        <ReactLoading type="spin" height="100%" width={40} color="#7d7d7d" />
        <strong>Aguarde, carregando...</strong>
      </div>
    </Container>
  );
};

export default Loading;
