/* eslint-disable no-alert */
import React from 'react';
import {
  RouteProps as ReactDOMRouteProps,
  Route as ReactDOMRoute,
  Redirect,
} from 'react-router-dom';

import { useAuth } from '~/hooks/auth';
import useCan from '~/hooks/useCan';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
  layout: React.ComponentType;
  permissions?: string | string[];
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  layout: Layout,
  permissions,
  ...rest
}) => {
  const { user } = useAuth();
  const can = useCan();

  const hasPermission = permissions ? can(permissions) : true;

  const homePage = '/';

  // const urlRedirect = useMemo(() => {
  //   if (search) {
  //     return new URLSearchParams(search).get('url');
  //   }
  //   return null;
  // }, [search]);

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return (
          <Layout>
            <Component />
          </Layout>
        );
      }}
    />
  );
};

export default Route;
