import React from 'react';

import { ChakraProvider } from '@chakra-ui/react';
import { ConfigProvider } from 'antd';
import pickerPtBR from 'antd/es/locale/pt_BR';

import theme from '~/styles/chakraTheme';

import { AuthProvider } from './auth';
import { CustomThemeProvider } from './theme';
import { TimerProvider } from './timer';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <ChakraProvider theme={theme}>
      <CustomThemeProvider>
        <ConfigProvider locale={pickerPtBR}>
          <TimerProvider>{children}</TimerProvider>
        </ConfigProvider>
      </CustomThemeProvider>
    </ChakraProvider>
  </AuthProvider>
);

export default AppProvider;
