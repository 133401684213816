/* eslint-disable react/no-children-prop */
import React, { SelectHTMLAttributes, useEffect, useRef } from 'react';
import { FiInfo, FiMinus } from 'react-icons/fi';

import {
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Select,
  FormErrorMessage,
  FormControl,
  Heading,
  Flex,
  Tooltip,
  Icon as IconChakra,
  IconButton,
} from '@chakra-ui/react';
import { useField } from '@unform/core';

export interface Option {
  value: number | string;
  label: number | string;
}

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  label?: string;
  isInline?: boolean;
  sizeCustom?: 'sm' | 'md' | 'lg' | undefined;
  leftAddon?: string;
  rightAddon?: string;
  containerStyle?: object;
  options: Option[];
  help?: string;
  background?: string;
  borderColor?: string;
  color?: string;
  reset?(): void;
  mb?: number | string;
  ml?: number[] | number | string;
  mr?: number[] | number | string;
  focusBorder?: string;
  readOnly?: boolean;
}

const SelectChakra: React.FC<SelectProps> = ({
  name,
  label,
  isInline = false,
  sizeCustom,
  leftAddon,
  rightAddon,
  containerStyle,
  options,
  help,
  background,
  borderColor,
  color,
  reset,
  mb,
  ml,
  mr,
  focusBorder,
  readOnly = false,
  ...rest
}) => {
  const selectRef = useRef<HTMLSelectElement>(null);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <FormControl
      mb={isInline ? 0 : mb || 4}
      ml={ml}
      mr={mr}
      style={containerStyle}
      isInvalid={!!error}
      flex={1}
    >
      {label && (
        <Flex alignItems="center" fontWeight="medium" mb={1}>
          {reset && (
            <Tooltip aria-label="Limpar" label="limpar" placement="top">
              <IconButton
                aria-label="Limpar"
                icon={<FiMinus />}
                variant="ghost"
                bg="red.200"
                _focus={{
                  boxShadow: 'none',
                }}
                _hover={{
                  bg: '#FC8181',
                }}
                colorScheme="red"
                mr={2}
                mb={0.2} // Para compensar o lineHeight do texto
                fontSize="0.5rem"
                minWidth="1rem"
                height="1rem"
                onClick={() => {
                  if (selectRef.current) selectRef.current.value = '';
                  reset();
                }}
              />
            </Tooltip>
          )}

          <Heading
            as="h5"
            fontSize="md"
            fontWeight={500}
            onClick={() => selectRef.current?.focus()}
            cursor="default"
            color="gray.500"
          >
            {label}
          </Heading>

          {help && (
            <Tooltip
              aria-label={help}
              placement="right"
              label={help}
              zIndex={1}
            >
              <IconChakra ml={2} color="gray.500" className="help-radio-icon" />
            </Tooltip>
          )}
        </Flex>
      )}

      <InputGroup size={sizeCustom || 'lg'} mb={isInline ? 0 : 2}>
        {leftAddon && <InputLeftAddon children={leftAddon} />}
        <Select
          roundedLeft={leftAddon ? 0 : undefined}
          roundedRight={rightAddon ? 0 : undefined}
          focusBorderColor={focusBorder || 'blue.500'}
          isInvalid={!!error}
          name={name}
          id={name}
          defaultValue={defaultValue}
          ref={selectRef}
          {...rest}
          size={sizeCustom || 'lg'}
          background={background || undefined}
          borderColor={borderColor || 'inherit'}
          color={color || undefined}
          isReadOnly={readOnly}
        >
          {options?.map((item) => (
            <option key={`${item.value}-${item.label}`} value={item.value}>
              {item.label}
            </option>
          ))}
        </Select>
        {rightAddon && <InputRightAddon children={rightAddon} />}
      </InputGroup>

      {error && <FormErrorMessage mb={2}>{error}</FormErrorMessage>}
    </FormControl>
  );
};

export default SelectChakra;
