/* import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  min-height: 100vh;
  color: ${({ theme }) => theme.colors.text};

  display: flex;
  align-items: stretch;
`;
 */
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: rgb(243 243 245);
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  z-index: 1;
  padding-right: 20px;
  height: 100%;
  min-height: calc(100vh - 64px);

  @media (max-width: 760px) {
    padding: 0 5px 5px;
  }
`;

export const Section = styled.section`
  flex: 1;
  background: ${({ theme }) => theme.colors.shape_primary};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 30px;
  max-width: calc(100% - 250px);
  width: 100%;

  @media (max-width: 760px) {
    max-width: 100%;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
  }
`;

export const BackgroundEffect = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 320px;
  background: ${({ theme }) => theme.colors.background_gradient};
  z-index: 0;
`;
