import React from 'react';
import ReactLoading from 'react-loading';

import { Container } from './styles';

interface Props {
  z_index?: number;
  min_height?: number;
}

const LoadingAbsolute: React.FC<Props> = ({ z_index, min_height }) => {
  return (
    <Container className="isloading" zIndex={z_index} minHeight={min_height}>
      <div>
        <ReactLoading type="spin" height="100%" width={40} color="#7d7d7d" />
        <strong>Aguarde, carregando...</strong>
      </div>
    </Container>
  );
};

export default LoadingAbsolute;
