/* eslint-disable react/no-children-prop */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiEye, FiEyeOff, FiInfo } from 'react-icons/fi';

import {
  InputGroup,
  Input,
  InputLeftElement,
  InputRightElement,
  InputLeftAddon,
  InputRightAddon,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  Tooltip,
  Flex,
  Icon as IconChakra,
} from '@chakra-ui/react';
import { useField } from '@unform/core';

import { cnpjMask, cpfMask, phoneMask, moneyMask } from '~/utils/mask';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  label?: string;
  type?: string;
  sizeCustom?: 'sm' | 'md' | 'lg';
  variant?: 'outline' | 'filled' | 'flushed' | 'unstyled';
  leftAddon?: string | React.ReactNode;
  rightAddon?: string | React.ReactNode;
  containerStyle?: object;
  isPassword?: boolean;
  isInline?: boolean;
  help?: string;
  background?: string;
  borderColor?: string;
  color?: string;
  mb?: number | string;
  mbInputGroup?: number | string;
  mask?: 'none' | 'cnpj' | 'cpf' | 'phone' | 'alterdata' | 'money';
  toUpperCase?: boolean;
  /*   onChange(value: string): void; */
}

const InputChakra: React.FC<InputProps> = ({
  name,
  icon: Icon,
  label,
  type = 'text',
  sizeCustom,
  variant,
  leftAddon,
  rightAddon,
  containerStyle,
  isPassword = false,
  isInline = false,
  help,
  background,
  borderColor,
  color,
  mb,
  mbInputGroup,
  mask,
  toUpperCase,
  required,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [show, setShow] = useState(false);
  const handleClick = (): void => setShow(!show);
  const typeInput = useMemo(() => {
    if (isPassword) {
      return 'password';
    }
    return type;
  }, [type, isPassword]);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  function handleCustomMask(value: string): void {
    // eslint-disable-next-line default-case
    switch (mask) {
      case 'cnpj':
        if (inputRef.current) {
          inputRef.current.value = cnpjMask(value);
        }
        break;
      case 'cpf':
        if (inputRef.current) {
          inputRef.current.value = cpfMask(value);
        }
        break;
      case 'phone':
        if (inputRef.current) {
          inputRef.current.value = phoneMask(value);
        }
        break;
      case 'alterdata':
        if (inputRef.current) {
          inputRef.current.value = value.replace(/\D+/g, '');
        }
        break;
      case 'money':
        if (inputRef.current) {
          inputRef.current.value = moneyMask(value);
        }
        break;
    }
  }

  function handleToUpperCase(value: string): void {
    if (inputRef.current) {
      inputRef.current.value = value.toUpperCase();
    }
  }

  return (
    <FormControl
      mb={isInline ? 0 : mb || 4}
      style={containerStyle}
      isInvalid={!!error}
      flex={1}
    >
      {label && (
        <Flex alignItems="center" fontWeight="medium">
          <Heading
            as="h5"
            size="sm"
            fontWeight={500}
            mb={1}
            onClick={() => inputRef.current?.focus()}
            cursor="default"
            color="gray.500"
          >
            {label}
          </Heading>

          {help && (
            <Tooltip aria-label={help} label={help} zIndex={1}>
              <Box as="span" ml="auto">
                <IconChakra as={FiInfo} color="gray.500" />
              </Box>
            </Tooltip>
          )}
        </Flex>
      )}

      <InputGroup
        size={sizeCustom || 'lg'}
        mb={isInline ? 0 : mbInputGroup || 2}
      >
        {Icon && (
          <InputLeftElement
            children={
              <Box
                as={Icon}
                size="20px"
                color={isFocused || isFilled ? 'blue.500' : 'gray.500'}
              />
            }
          />
        )}
        {leftAddon && <InputLeftAddon children={leftAddon} />}
        {!mask && !toUpperCase ? (
          <Input
            type={show ? 'text' : typeInput}
            roundedLeft={leftAddon ? 0 : undefined}
            roundedRight={rightAddon ? 0 : undefined}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            focusBorderColor="blue.500"
            isInvalid={!!error}
            name={name}
            id={name}
            defaultValue={defaultValue}
            ref={inputRef}
            {...rest}
            size={sizeCustom || 'lg'}
            variant={variant || 'outline'}
            background={background || undefined}
            borderColor={borderColor || 'inherit'}
            color={color || undefined}
          />
        ) : (
          <Input
            type={show ? 'text' : typeInput}
            roundedLeft={leftAddon ? 0 : undefined}
            roundedRight={rightAddon ? 0 : undefined}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            focusBorderColor="blue.500"
            isInvalid={!!error}
            name={name}
            id={name}
            defaultValue={defaultValue}
            ref={inputRef}
            onChange={(e) => {
              if (mask) {
                handleCustomMask(e.target.value);
              } else if (toUpperCase) {
                handleToUpperCase(e.target.value);
              }
            }}
            {...rest}
            size={sizeCustom || 'lg'}
            variant={variant || 'outline'}
            background={background || undefined}
            borderColor={borderColor || 'inherit'}
            color={color || undefined}
          />
        )}

        {rightAddon && <InputRightAddon children={rightAddon} />}

        {isPassword && (
          <InputRightElement width="4.5rem">
            <Button
              variant="ghost"
              color="gray.500"
              h="1.75rem"
              size="sm"
              onClick={handleClick}
            >
              {show ? <IconChakra as={FiEyeOff} /> : <IconChakra as={FiEye} />}
            </Button>
          </InputRightElement>
        )}
      </InputGroup>

      {error && <FormErrorMessage mb={2}>{error}</FormErrorMessage>}
    </FormControl>
  );
};

export default InputChakra;
