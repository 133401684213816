import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { FiEye } from 'react-icons/fi';
import { useLocation, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import {
  Box,
  Container,
  Flex,
  Image,
  Text,
  useToast,
  IconButton,
} from '@chakra-ui/react';
import { format, parseISO } from 'date-fns';
import queryString from 'query-string';

import { User } from '~/@types/Users';
import logoImg from '~/assets/logo-form.png';
import { IPaginationProps } from '~/shared/components/Pagination';
import SectionHeader from '~/shared/components/SectionHeader';
import Table, { THeadProps } from '~/shared/components/Table';
import api from '~/shared/services/api';
import { phoneMask } from '~/utils/mask';

interface Survey extends IPaginationProps {
  data: User[];
}

const ListSurvey: React.FC = () => {
  const [surveyUsers, setSurveyUsers] = useState<Survey>();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const addToast = useToast();

  const queryParams = useMemo(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  const tableTitles = useMemo((): THeadProps[] => {
    return [
      {
        title: 'Nome',
      },
      {
        title: 'Email',
      },
      {
        title: 'Telefone',
      },
      {
        title: 'Criado em',
      },
      {
        title: 'Ação',
      },
    ];
  }, []);

  useEffect(() => {
    setLoading(true);
    try {
      api
        .get<Survey>(`/survey/${queryParams.t}/users`, { params: { page } })
        .then((response) => {
          const resp = response.data;
          setSurveyUsers({
            ...resp,
            data: resp.data.map((dt) => {
              return {
                ...dt,
                created_at: format(
                  parseISO(dt.created_at),
                  "dd/MM/yyyy 'às' HH:mm",
                ),
              };
            }),
          });
        });
    } catch (err) {
      const { error } = err.response.data;

      addToast({
        position: 'top-right',
        isClosable: true,
        status: 'error',
        title: 'Erro na visualização',
        description: error,
      });
    } finally {
      setLoading(false);
    }
  }, [queryParams, addToast, page]);

  return (
    <Container maxWidth="1000px" pos="relative">
      <Flex justifyContent="center">
        <Box marginTop="20px">
          <Image
            width="544px"
            height="136px"
            objectFit="contain"
            src={logoImg}
            alt="MyIPAC"
            mb={10}
          />
        </Box>
      </Flex>

      {surveyUsers && (
        <>
          <Flex justifyContent="center">
            <SectionHeader title="Lista de Usuarios Holding" />
          </Flex>
          <Box>
            <Table
              theadData={tableTitles}
              loading={loading}
              pagination={{
                current_page: surveyUsers?.current_page,
                last_page: surveyUsers?.last_page,
                per_page: surveyUsers?.per_page,
                to: surveyUsers?.to,
                total: surveyUsers?.total,
              }}
              newPage={(pg) => setPage(pg)}
            >
              {!!surveyUsers?.data.length &&
                surveyUsers?.data?.map((user) => {
                  return (
                    <Box
                      width="100%"
                      background="#eeeeee35"
                      as="tr"
                      key={user.id}
                      backgroundColor="#fff"
                    >
                      <Box as="td" py={3}>
                        <Text ml={3} as="b">
                          {user.name}
                        </Text>
                      </Box>
                      <Box as="td" py={3} minWidth="270px">
                        <Text ml={3} as="b" wordBreak="break-all">
                          {user.email}
                        </Text>
                      </Box>
                      <Box as="td" py={3} minWidth="145px">
                        <Text ml={3} as="b">
                          {phoneMask(user.phone)}
                        </Text>
                      </Box>
                      <Box as="td" py={3} minWidth="170px">
                        <Text ml={3} as="b">
                          {user.created_at}
                        </Text>
                      </Box>
                      <Box as="td" py={3} minWidth="100px">
                        <Link
                          to={`/questionarios/holding/usuarios/${user.id}?t=${queryParams.t}`}
                          style={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }}
                        >
                          <IconButton
                            aria-label="Visualizar"
                            icon={<FiEye />}
                            variant="ghost"
                            bg="blue.50"
                            ml="20px"
                            colorScheme="blue"
                          />
                        </Link>
                      </Box>
                    </Box>
                  );
                })}
            </Table>
          </Box>
        </>
      )}
    </Container>
  );
};

export default ListSurvey;
