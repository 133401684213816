import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
  Fragment,
} from 'react';
import { FaArrowCircleLeft } from 'react-icons/fa';
import { FiArrowLeftCircle, FiArrowLeft } from 'react-icons/fi';
import { useLocation, useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Text,
  Container,
  Image,
  Flex,
  IconButton,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import queryString from 'query-string';

// import { User } from '~/@types/Users';
import logoImg from '~/assets/logo-form.png';
import InputChakra from '~/shared/components/InputChakra';
import CheckboxInput from '~/shared/components/InputChakra/CheckboxChakra';
import SelectChakra from '~/shared/components/InputChakra/SelectChakra';
import LoadingAbsolute from '~/shared/components/LoadingAbsolute';
import SectionHeader from '~/shared/components/SectionHeader';
import api from '~/shared/services/api';
import { phoneMask } from '~/utils/mask';

interface ReasonChangeAccounting {
  id: number;
  name: string;
}

interface SubjectInterest {
  id: number;
  name: string;
}

interface Companies {
  id: string;
  user_id: number;
  tributary_regime_id: number;
  name: string;
  branch_activity: string;
  number_branches: number;
  monthly_billing: string;
  created_at: string;
  updated_at: string;
}

interface User {
  id: number;
  name: string;
  email: string;
  phone: string;
  created_at: string;
  updated_at: string;
}

interface SurveyOptions {
  id: number;
  name: string;
  label: string;
  value: string;
  visible?: boolean;
}

interface Filters {
  relativies: SurveyOptions[];
  civil_status: SurveyOptions[];
  property_types: SurveyOptions[];
  tributary_regimes: SurveyOptions[];
}

interface UserSurvey extends User {
  survey: {
    id: number;
    name: string;
    token: string;
    description: string;
    created_at: string;
    updated_at: string;
  };
  ca_companies: Companies[];
  subjects_interest: SubjectInterest[];
  reason_change_accounting: ReasonChangeAccounting[];
  // reason_change_accounting: HoldCompanies[];
}

interface Params {
  userId: string;
}

const ViewConsultativeAnalysis: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingFilters, setLoadingFilters] = useState(true);

  const [survey, setSurvey] = useState<UserSurvey>();
  const [user, setUser] = useState<User>();
  const [subjectInterest, setSubjectInterest] = useState<SubjectInterest[]>([]);
  const [companies, setCompanies] = useState<Companies[]>([]);
  const [reasonChangeAccounting, setReasonChangeAccounting] = useState<
    ReasonChangeAccounting[]
  >([]);
  const history = useHistory();

  const { userId } = useParams<Params>();

  const [relativiesTypes, setRelativieTypes] = useState<SurveyOptions[]>([]);
  const [civilStatus, setCivilStatus] = useState<SurveyOptions[]>([]);
  const [propertyTypes, setPropertyTypes] = useState<SurveyOptions[]>([]);
  const [tributaryRegimeTypes, setTributaryRegimeTypes] = useState<
    SurveyOptions[]
  >([]);

  const location = useLocation();
  const queryParams = useMemo(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  const responsibilitiesOptions = [
    { label: 'Sócio', value: '1' },
    { label: 'Gestor', value: '2' },
    { label: 'Técnico', value: '3' },
  ];

  const subjectInterestOptions = [
    { id: '1', label: 'Tributário', value: '1' },
    { id: '2', label: 'Trabalhista', value: '2' },
    { id: '3', label: 'Societário', value: '3' },
    { id: '4', label: 'Contábil', value: '4' },
  ];

  const questions = [
    {
      id: '1',
      label: 'Preciso de uma contabilidade mais consultiva.',
      value: '1',
    },
    {
      id: '2',
      label:
        'A contabilidade atual não consegue mais me atender por conta do meu faturamento (regime tributário) ou por conta do seguimento de atuação da minha empresa ou porque sinto que a contabilidade está defasada tecnicamente.',
      value: '2',
    },
    { id: '3', label: 'Busco por honorários mais baratos.', value: '3' },
    {
      id: '4',
      label: 'Não consigo esclarecer dúvidas com a minha contabilidade.',
      value: '4',
    },
    {
      id: '5',
      label:
        'Não confio mais nas informações e informes gerados por minha contabilidade atual.',
      value: '5',
    },
  ];

  /*   const goLink = useMemo(() => {
    return `/questionarios/holding/usuarios?t=${queryParams.t}`;
  }, [queryParams.t]); */
  const getData = useCallback(() => {
    setLoadingData(true);
    api
      .get<UserSurvey>(`/survey/consultative-analysis/users/${userId}`)
      .then((response) => {
        const { data } = response;
        const {
          ca_companies,
          reason_change_accounting,
          subjects_interest,
        } = data;
        console.log(data);
        setSurvey(data);
        setUser({
          created_at: data.created_at,
          email: data.email,
          id: data.id,
          name: data.name,
          phone: data.phone,
          updated_at: data.updated_at,
        });
        setSubjectInterest(subjects_interest);
        setCompanies(ca_companies);
        setReasonChangeAccounting(reason_change_accounting);

        // console.log(data.survey.token, data.id);
      })

      .finally(() => setLoadingData(false));
  }, [userId]);

  const getSelect = useCallback(() => {
    setLoadingFilters(true);
    api
      .get<Filters>(`/survey/filters`)

      .then((response) => {
        const {
          civil_status,
          property_types,
          relativies,
          tributary_regimes,
        } = response.data;

        setRelativieTypes(
          relativies.map((rel) => {
            return { ...rel, label: rel.name, value: rel.id.toString() };
          }),
        );
        setPropertyTypes(
          property_types.map((pt) => {
            return { ...pt, label: pt.name, value: pt.id.toString() };
          }),
        );
        setCivilStatus(
          civil_status.map((cs) => {
            return { ...cs, label: cs.name, value: cs.id.toString() };
          }),
        );
        setTributaryRegimeTypes(
          tributary_regimes.map((tr) => {
            return { ...tr, label: tr.name, value: tr.id.toString() };
          }),
        );
      })
      .finally(() => setLoadingFilters(false));
  }, []);

  useEffect(() => {
    getSelect();
    getData();
  }, [getData, getSelect]);

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Container pos="relative">
      {loadingData && loadingFilters && (
        <LoadingAbsolute z_index={1111} min_height={500} />
      )}
      {!loadingData && !loadingFilters && (
        <>
          <Box display="flex" alignItems="center">
            <IconButton
              aria-label="Voltar"
              icon={<FiArrowLeft />}
              size="lg"
              borderRadius="md"
              ml={3}
              mt={3}
              onClick={handleGoBack}
              border="2px solid #4e545e"
              // borderColor="black"
            />
            <Text ml={3} mt={3}>
              Voltar
            </Text>
          </Box>

          <Box marginTop="20px">
            <Image
              width="100%"
              objectFit="contain"
              src={logoImg}
              alt="MyIPAC"
              mb={10}
            />
            <Flex justifyContent="center">
              <SectionHeader title={`Respondido por ${user?.name}`} />
            </Flex>
          </Box>

          <Form
            ref={formRef}
            onSubmit={() => ''}
            initialData={{
              subjects_interest: subjectInterest?.map((sub) =>
                sub.id.toString(),
              ),
              reason_change_accounting: reasonChangeAccounting.map((rea) =>
                rea.id.toString(),
              ),
            }}
          >
            <Box>
              {user && (
                <Container
                  backgroundColor="#fff"
                  border="1px solid rgb(218,220,224)"
                  borderRadius="8px"
                  position="relative"
                  paddingTop="22px"
                  padding="24px"
                  marginBottom="12px"
                  marginTop="12px"
                >
                  <Flex justifyContent="center">
                    <Text marginBottom="10px" fontSize="4xl">
                      Dados Pessoais
                    </Text>
                  </Flex>

                  <InputChakra
                    name="name"
                    label="Nome"
                    background="#e9ecef"
                    borderColor=""
                    mb="0px"
                    value={user.name}
                    readOnly
                  />
                  <InputChakra
                    name="email"
                    label="E-mail"
                    background="#e9ecef"
                    borderColor=""
                    mb="0px"
                    value={user.email}
                    readOnly
                  />
                  <InputChakra
                    name="phone"
                    label="Telefone"
                    background="#e9ecef"
                    borderColor=""
                    mb="0px"
                    value={phoneMask(user.phone)}
                    mask="phone"
                    readOnly
                  />
                </Container>
              )}
              <Box>
                {companies?.map((cp, idx) => {
                  const num = idx + 1;

                  return (
                    <Fragment key={num}>
                      <Container
                        backgroundColor="#fff"
                        border="1px solid rgb(218,220,224)"
                        borderRadius="8px"
                        position="relative"
                        paddingTop="22px"
                        padding="24px"
                        marginBottom="12px"
                        marginTop="12px"
                      >
                        {idx === 0 && (
                          <Flex justifyContent="center">
                            <Text fontSize="4xl" marginBottom="10px">
                              Empresas
                            </Text>
                          </Flex>
                        )}

                        <Text
                          color="#718096"
                          display="flex"
                          flexDirection="row-reverse"
                        >
                          {`Empresa (${idx + 1})`}
                        </Text>

                        <InputChakra
                          name={`companies[${idx}].name`}
                          label="Nome da Empresa"
                          background="#e9ecef"
                          borderColor=""
                          mb="0px"
                          value={cp?.name}
                          readOnly
                        />

                        <InputChakra
                          name={`companies[${idx}].branch_activity`}
                          label="Ramo de Atividade"
                          background="#e9ecef"
                          borderColor=""
                          mb="0px"
                          value={cp?.branch_activity}
                          readOnly
                        />

                        <SelectChakra
                          name={`companies[${idx}].tributary_regime_id`}
                          label="Regime Tributário"
                          background="#e9ecef"
                          borderColor=""
                          mb="0px"
                          options={tributaryRegimeTypes}
                          // placeholder="Selecione"
                          value={cp?.tributary_regime_id}
                          disabled
                        />

                        <InputChakra
                          name={`companies[${idx}].number_branches`}
                          label="Quantidade de Filiais"
                          background="#e9ecef"
                          borderColor=""
                          mb="0px"
                          type="number"
                          value={cp?.number_branches}
                          readOnly
                        />
                        <InputChakra
                          name={`companies[${idx}].monthly_billing`}
                          label="Faturamento Mensal"
                          background="#e9ecef"
                          borderColor=""
                          mb="0px"
                          value={cp?.monthly_billing}
                          leftAddon="R$"
                          // mask="money"
                          // maxLength={15}
                          readOnly
                        />
                      </Container>
                    </Fragment>
                  );
                })}
                <Container
                  backgroundColor="#fff"
                  border="1px solid rgb(218,220,224)"
                  borderRadius="8px"
                  position="relative"
                  paddingTop="22px"
                  padding="24px"
                  marginBottom="12px"
                  marginTop="12px"
                >
                  <Flex justifyContent="center">
                    <Text fontSize="4xl" marginBottom="12px">
                      Assunto de Interesse
                    </Text>
                  </Flex>
                  <CheckboxInput
                    name="subjects_interest"
                    options={subjectInterestOptions}
                  />
                </Container>
                <Container
                  backgroundColor="#fff"
                  border="1px solid rgb(218,220,224)"
                  borderRadius="8px"
                  position="relative"
                  paddingTop="22px"
                  padding="24px"
                  marginBottom="12px"
                  marginTop="12px"
                >
                  <Flex justifyContent="center">
                    <Text fontSize="2xl" marginBottom="12px">
                      Você precisa ou quer mudar de contabilidade?
                    </Text>
                  </Flex>
                  {reasonChangeAccounting.length && reasonChangeAccounting ? (
                    <CheckboxInput
                      name="reason_change_accounting"
                      options={questions}
                    />
                  ) : (
                    <Text>
                      {user?.name} não precisa ou não quer mudar de
                      contabilidade.
                    </Text>
                  )}
                </Container>
              </Box>
            </Box>
          </Form>
        </>
      )}
    </Container>
  );
};

export default ViewConsultativeAnalysis;
